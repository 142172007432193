/* NX = not expanded */

.side-nav-container {
	background-color: var(--dark);
	width: 300px;
	height:auto;
	position: relative;
	color: var(--light);
	transition: 0.8s;
}
.side-nav-container-NX {
	width: 95px;
}

.side-nav-upper,
.side-nav-heading,
.side-nav-menu,
.side-menu-item,
.side-nav-footer {
	/*border: 2px solid white; */
	display: grid;
}


.side-nav-heading {
	grid-template-columns: 3fr 3fr;
	grid-template-rows: 1fr;
}


.side-nav-brand {
	display: flex;
	margin-top: 15px;
	color: var(--light);
	margin-bottom: 20px;
	margin-top: 80px;	
}
.side-nav-brand img {
	margin-top: -10px;
	width: 50px;
	margin-right: 10px;
	
}

.hamburger {
	background: none;
	cursor: pointer;
	margin: auto;
	margin-top: 85px;
	border: none;

	
}

.hamburger span {
	display: block;
	margin-top: 5px;
	background-color: var(--light);
	border-radius: 10px;
	height: 5px;
	width: 35px;
	transition: 0.8s;
}

.hamburger:hover span {
	background-color: var(--primary);
	
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 30px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}


/* Expanded */


.hamburger-out {
	margin-left: 25px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	
	transform: translate(14px, -4px) rotate(155deg);
}

.side-nav-menu {
	grid-template-rows: repeat(11, 1fr);
	margin-top: 25px;
	

}

.side-menu-item {
	height: 80px;
	font-size: 20px;
	font-weight: 500;
	display: flex;
	color: var(--light);
	text-decoration: none;
	text-transform: uppercase;
	margin: auto 23px;
	border-radius: 20px;
	margin-top: 9px;
	padding-left: 10px;
	
	

	
}

.side-menu-item-NX {
	margin: auto;
	
}

.side-menu-item:hover {
	background-color: var(--primary);
	color: #ffff;
	transition: 0.4s;
	

}



.side-menu-item img {
	width: 30px;
	margin-right: 15px;
	padding: 0px;
	

}

.side-nav-footer {
	width: 100%;
	height: 70px;
	position: absolute;
	bottom: 1%;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
	
}

.side-nav-details {
	display: flex;

}
.side-nav-details img {
	width: 100px;
	padding: 0 26px;
	margin-top: -20px;
}

.side-nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
	margin-left: -15px;

}

.side-nav-footer-user-position {
	margin-top: -10px;
	color: #ffff;
	margin-left: -15px;

}
.logout-icon {
	width: 70px;
	margin: auto;
	border-radius: 90px;
	padding: 10px;
	margin-left: 15px;
	margin-top: -10px;
}
.logout-icon:hover {
	background-color: var(--primary);
}

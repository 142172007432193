.dropdown-toggle::after {
   content: none;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
   display: contents;
}

.navbar-nav {
   --bs-nav-link-padding-x: 1;
}

#profile {
   font-size: 33px;
}

.nameTxtmain {
   color: rgb(255, 255, 255);
}

.iconsColormain {
   color: #ffffff;
}

.nameTxt {
   color: #55728f;
}

.iconsColor {
   color: #55728f;
}

.navbarbg {
   background-color: #55728f;
}

/* #29aed6; */
form.d-flex {
   margin-left: 1%;
}

button.navbar-toggler.collapsed {
   margin-left: 20px;
}

@media (min-width: 320px)and (max-width: 767px) {
   p.nameTxtmain {
      position: relative;
      margin-left: 5px;
      top: 10px;
  }
   svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.iconsColormain.css-i4bv87-MuiSvgIcon-root {
      font-size: 40px;
   }

   svg#profile {
      font-size: 41px;
   }
}
.bodyM{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}
.form-style{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    height: 100%;
    width: 100%;
}
.flex{
    height: 18px;
    }

.lab{
    padding-left: 6%;
    color: rgb(48, 47, 47);
}
.emaill{
    padding-left: 6%;
    color: rgb(48, 47, 47);
}
.phone{
    padding-left: 6%;
    color: rgb(48, 47, 47);
}
.city{
    padding-left: 6%;
    color: rgb(48, 47, 47);
}
#name{
    width: 56%;
    height: 8%;
    margin-left: 6%;
    border-color: #d3d3d3;
}
#email{
    width:56%;
    height: 8%;
    margin-left: 6%;
    border-color: #d3d3d3;
}
#city{
    width: 40%;
    height: 8%;
    margin-left: 6%;
    border-color: #d3d3d3;
}
#phone{
    width: 40%;
    margin-left: 6%;
   
    border-color: #d3d3d3;
}
.password{
    padding-left: 6%;
    color: rgb(48, 47, 47);

}
#password{
    width: 40%;
    margin-left: 7%;
    border-color: #d3d3d3;
    height: 1%;
   
}
#gender{
    width: 20%;
    height: 8%;
    margin-left: 6%;
    border-color: #d3d3d3;
}
#gen-pass{
    width: 20%;
    height: 34px;
    font-size: 14px;
    margin-right: 27%;
}
.error{
    color: red;
    margin-left: 6%;
}
.customer{
    height: 60px;;
}
#errorp{
    color: red;
    margin-left: 6%;
}
#savetBtn{
    background-color: #55728f;
border: #55728f;
}



.formBody{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}
.form-style{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    height: 100%;
    width: 100%;
}
.flex{
    height: 20px;
    }

.Labels{
    padding-left: 6%;
}
.descLabel{
    padding-left: 6%;
}
#title{
    width: 70%;
    height: 8%;
    margin-left: 6%;
}
#desc{
    width: 70%;
    margin-left: 6%;
}
#pic{
    margin-left: 6%;
}
#price{
    width: 30%;
    margin-left: 6%;
    height: 90%;
}
#currency{
    width: 10%;
    margin-left: 1%;
    height: 36px;
}
#categ{
    width: 60%;
    height: 8%;
    margin-left: 6%;
}
#check{
    width: 45%;
    margin-left: 6%;
}
.space{
    height: 20px;
}
.priceInputFlex{
   display: flex;
   flex-direction:row ;

}
#submitBtn{
background-color: #55728f;
border: #55728f;
}
.errorMSG{
    color:red;
    margin-left: 15%;
}


.body{
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    display: flex;
    position: relative;
}
.form-style{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    height: 100%;
    width: 100%;
}
.flex{
    height: 10px;
}
#subbtn{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    background-color: #007FFF;
    color: white;
    border: #007FFF;
    margin-left: 6%;
}
.lab{
    color: gray;
}

.FormInput{
    width: 70%;
    height: 8%;
    margin-left: 6%;
    border-radius: 10px;
    border-color: #d3d3d3;
}